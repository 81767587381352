import styled from "@emotion/styled";
import { fontMixtaSharp, fontRoboto, theme } from "@product/scmp-sdk";

import {
  FlexContainer,
  IconContainer,
} from "scmp-app/components/content/content-action-bar/styles";
import { Flag } from "scmp-app/components/content/content-headline/content-headline-tag/styles";
import {
  Headline as ContentHeadline,
  SponsorHeadline,
} from "scmp-app/components/content/content-headline/styles";
import { EntityLink } from "scmp-app/components/entity-link";
import { SponsorTagContainer } from "scmp-app/components/sponsor-tag/styles";

export const StyledEntityLink = styled(EntityLink)`
  display: block;
`;

export const CoverImage = styled.div`
  grid-area: cover-image;

  inline-size: 100%;
  aspect-ratio: 3/2;
`;

export const Headline = styled.div`
  grid-area: headline;

  margin-block-start: 24px;

  ${theme.breakpoints.only("tablet")} {
    margin-block-start: 0;
  }

  ${ContentHeadline}, ${SponsorHeadline} {
    color: #ffffff;
    font-weight: 400;
    font-size: 26px;
    font-family: ${fontMixtaSharp};
    font-style: normal;
    line-height: 110%;

    ${theme.breakpoints.only("tablet")} {
      font-size: 18px;
      line-height: 120%;
    }
  }

  ${Flag} {
    font-weight: 400;
    font-size: 26px;
    font-family: ${fontMixtaSharp};
    font-style: normal;
    line-height: 110%;

    ${theme.breakpoints.only("tablet")} {
      font-size: 18px;
      line-height: 120%;
    }
  }
`;

export const ActionBar = styled.div`
  grid-area: action-bar;

  inline-size: 100%;
  margin-block-start: 14px;

  color: #a1a1a1;
  font-weight: 400;
  font-size: 12px;
  font-family: ${fontRoboto};
  font-style: normal;
  line-height: 24px;

  ${theme.breakpoints.only("tablet")} {
    margin-block-start: 12px;
  }

  ${IconContainer} {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }

  ${SponsorTagContainer}+${FlexContainer} {
    display: none;
  }
`;

export const Container = styled.div`
  display: grid;
  grid:
    "cover-image" min-content
    "headline" min-content
    "action-bar" min-content
    / 100%;

  ${theme.breakpoints.only("tablet")} {
    grid:
      "cover-image headline" min-content
      "cover-image action-bar" min-content
      / 96px 1fr;

    column-gap: 12px;
  }
`;
