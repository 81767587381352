import "swiper/css";
import "swiper/css/grid";
import styled from "@emotion/styled";
import { fontRoboto, fontRobotoCondensed, theme } from "@product/scmp-sdk";
import { Swiper, SwiperSlide } from "swiper/react";

import { BaseImage } from "scmp-app/components/common/base-image";
import { HundredTopTables } from "scmp-app/components/content/content-item-render/variants/hundred-top-tables";
import { EntityLink } from "scmp-app/components/entity-link";
import {
  SponsorName,
  SponsorTagContainer,
  SponsorType,
} from "scmp-app/components/sponsor-tag/styles";

import { HundredTopTablesFakeItem } from "./fake-item";

export const StyledHundredTopTables = styled(HundredTopTables)``;
export const StyledHundredTopTablesSponsor = styled(HundredTopTables)``;
export const StyledHundredTopTablesFakeItem = styled(HundredTopTablesFakeItem)``;
export const StyledSwiper = styled(Swiper)``;
export const StyledSwiperSlide = styled(SwiperSlide)``;

export const Container = styled.div`
  inline-size: 100%;
  margin-inline: auto;
  padding-block: 24px;
  padding-inline: 20px;

  border-radius: 8px;

  background-color: #1f2f3f;
  background-blend-mode: soft-light, normal;

  ${theme.breakpoints.only("tablet")} {
    padding-block: 32px;
  }
`;

export const Header = styled.div`
  color: #999999;
  font-weight: 400;
  font-size: 14px;
  font-family: ${fontRobotoCondensed};
  font-style: normal;
  line-height: normal;
  letter-spacing: 1.4px;
  text-align: center;
  text-transform: uppercase;
`;

export const Logo = styled(BaseImage)`
  inline-size: 130px;
  margin-inline: auto;
  padding-block: 24px 32px;

  ${theme.breakpoints.only("tablet")} {
    padding-block: 16px 24px;
  }
`;

export const ItemsContainer = styled.div`
  ${StyledHundredTopTablesFakeItem}, ${StyledHundredTopTables}, ${StyledHundredTopTablesSponsor} {
    padding-block-end: 16px;
    border-block-end: 1px #999999 dashed;

    ${theme.breakpoints.only("tablet")} {
      padding-block-end: 12px;
    }

    ${SponsorTagContainer} {
      gap: 0;

      ${SponsorType} {
        color: #888888;
        font-weight: 400;
        font-size: 12px;
        font-family: ${fontRoboto};
        font-style: normal;
        line-height: normal;
      }

      ${SponsorName} {
        color: #888888;
        font-weight: 700;
        font-size: 12px;
        font-family: ${fontRoboto};
        font-style: normal;
        line-height: normal;
      }
    }
  }

  ${theme.breakpoints.only("mobile")} {
    ${".swiper"} {
      padding-block-end: 21px;
    }

    ${".swiper-pagination-bullet"} {
      inline-size: 8px;
      block-size: 8px;

      background-color: #bbbbbb;
    }

    ${".swiper-pagination-bullet-active"} {
      background-color: #c79a00;
    }

    ${StyledHundredTopTablesFakeItem}, ${StyledHundredTopTables} {
      margin-block-end: 24px;
    }
  }

  ${theme.breakpoints.only("tablet")} {
    display: grid;
    grid-template-rows: repeat(4, minmax(auto, 1fr));
    grid-template-columns: 1fr 1fr;
    gap: 12px 24px;

    ${StyledHundredTopTablesFakeItem} {
      border: none;
    }

    :not(:has(${StyledHundredTopTablesSponsor})) {
      ${StyledHundredTopTables} {
        :nth-child(n + 6) {
          display: none;
        }
        :nth-child(5) {
          border-block-end: none;
        }
      }
    }

    :has(${StyledHundredTopTablesSponsor}) {
      ${StyledHundredTopTables} {
        :nth-child(n + 5) {
          display: none;
        }
      }
      ${StyledHundredTopTablesSponsor} {
        border-block-end: none;
      }
    }
  }

  ${theme.breakpoints.up("desktop")} {
    display: flex;
    flex-flow: column;
    gap: 24px;

    :not(:has(${StyledHundredTopTablesSponsor})) {
      ${StyledHundredTopTables} {
        :nth-child(n + 5) {
          display: none;
        }
      }
    }

    :has(${StyledHundredTopTablesSponsor}) {
      ${StyledHundredTopTables} {
        :nth-child(n + 4) {
          display: none;
        }
      }
    }
  }
`;

export const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: flex-start;

  padding-block-start: 24px;

  ${theme.breakpoints.between("tablet", "desktop")} {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    border-block-start: 1px #999999 dashed;
  }
`;

export const MoreOnButton = styled(EntityLink)`
  padding-block: 7px;
  padding-inline: 8px;

  color: #ffffff;
  font-weight: 400;
  font-size: 14px;
  font-family: ${fontRoboto};
  font-style: normal;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;

  border: 1px solid #ffffff;
  border-radius: 2px;
`;

export const Disclaimer = styled.div`
  color: rgba(255, 255, 255, 0.5);
  font-weight: 400;
  font-size: 12px;
  font-family: ${fontRoboto};
  font-style: italic;
  line-height: normal;
`;
