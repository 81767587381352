import { notEmpty, PageType, theme, useResponsive } from "@product/scmp-sdk";
import { type FunctionComponent, useCallback, useMemo } from "react";
import { graphql, useFragment } from "react-relay";
import { Grid, Pagination } from "swiper/modules";

import { BaseLinkContextProvider } from "shared/components/common/base-link/context";
import { useCurrentPageType } from "shared/hooks";

import type { hundredTopTablesDiscoveryWidgetQuery$key } from "scmp-app/queries/__generated__/hundredTopTablesDiscoveryWidgetQuery.graphql";

import ImageLogo from "./logo.png";
import {
  BottomContainer,
  Container,
  Disclaimer,
  Header,
  ItemsContainer,
  Logo,
  MoreOnButton,
  StyledHundredTopTables,
  StyledHundredTopTablesFakeItem,
  StyledHundredTopTablesSponsor,
  StyledSwiper,
  StyledSwiperSlide,
} from "./styles";

type Props = {
  className?: string;
  reference: hundredTopTablesDiscoveryWidgetQuery$key;
};

export const HundredTopTablesDiscoveryWidget: FunctionComponent<Props> = ({
  className,
  reference,
}) => {
  const data = useFragment(
    graphql`
      fragment hundredTopTablesDiscoveryWidgetQuery on Query {
        hundredTopTablesLast7Days: queue(
          filter: { name: "scmp_trending_section_scmp_trending_section_100_top_tables_last_7_days" }
        ) {
          items(first: 5) {
            edges {
              node {
                ... on Article {
                  entityId
                  ...hundredTopTablesContent
                }
              }
            }
          }
        }
        hundredTopTablesSponsor: queue(
          filter: { name: "discovery_module_discovery_module_517759" }
        ) {
          items(first: 1) {
            edges {
              node {
                ... on Article {
                  ...hundredTopTablesContent
                }
              }
            }
          }
        }
        hundredTopTablesSection: section(filter: { entityId: "517759" }) {
          ...entityLink
        }
      }
    `,
    reference,
  );

  const currentPageType = useCurrentPageType();
  const isMobile = useResponsive(theme.breakpoints.only("mobile"));

  const pgtype = useMemo(() => {
    switch (currentPageType) {
      case PageType.Section:
      case PageType.SubSection:
        return "section";
      case PageType.Topic:
      case PageType.TopicCollection:
      case PageType.Topics:
        return "topic";
    }
  }, [currentPageType]);

  const renderedItems = useMemo(() => {
    const [sponsorItem] = data?.hundredTopTablesSponsor?.items?.edges ?? [];
    const last7DaysItems = data?.hundredTopTablesLast7Days?.items?.edges ?? [];

    const fakeItemNode = <StyledHundredTopTablesFakeItem key="fake" />;
    const sponsorNode = sponsorItem?.node ? (
      <StyledHundredTopTablesSponsor key="sponsor" reference={sponsorItem?.node} />
    ) : null;
    const nodes = last7DaysItems.map(({ node }) => (
      <StyledHundredTopTables key={node.entityId} reference={node} />
    ));

    const results = isMobile
      ? [fakeItemNode, sponsorNode, ...nodes]
      : [fakeItemNode, ...nodes, sponsorNode];

    return results.filter(notEmpty);
  }, [
    data?.hundredTopTablesLast7Days?.items?.edges,
    data?.hundredTopTablesSponsor?.items?.edges,
    isMobile,
  ]);

  const renderSwiper = useCallback(() => {
    // originally we have any array of articles, e.g. { [0], [1], [2], [3], [4], [5] }
    // with swiper, the render sequence would be:
    //     { [0] } { [1] } { [2] }
    //     { [3] } { [4] } { [5] }
    // we need to remap the items into
    //     { [0] } { [2] } { [4] }
    //     { [1] } { [3] } { [5] }
    const remappedItems = [0, 2, 4, 1, 3, 5].map(index => renderedItems?.[index]).filter(notEmpty);
    return (
      <StyledSwiper
        grid={{
          fill: "row",
          rows: 2,
        }}
        modules={[Pagination, Grid]}
        pagination={true}
        spaceBetween={20}
      >
        {remappedItems.map((item, index) => (
          <StyledSwiperSlide key={index}>{item}</StyledSwiperSlide>
        ))}
      </StyledSwiper>
    );
  }, [renderedItems]);

  return (
    <BaseLinkContextProvider
      customQueryParameters={{
        module: "100_top_tables",
        pgtype,
      }}
    >
      <Container className={className}>
        <Header>highlights from</Header>
        <Logo $aspectRatio="auto" $objectFit="contain" disableLinkingData src={ImageLogo.src} />

        <ItemsContainer>{isMobile ? renderSwiper() : renderedItems}</ItemsContainer>

        <BottomContainer>
          <MoreOnButton reference={data.hundredTopTablesSection}>
            More from 100 top tables
          </MoreOnButton>
          <Disclaimer>{`Content from SCMP's Specialist Publications`}</Disclaimer>
        </BottomContainer>
      </Container>
    </BaseLinkContextProvider>
  );
};
