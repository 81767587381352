/**
 * @generated SignedSource<<5a687429761d3870304b9a0295bc65ae>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type hundredTopTablesContent$data = {
  readonly " $fragmentSpreads": FragmentRefs<"entityLink" | "hooksContentItemProvidersContent">;
  readonly " $fragmentType": "hundredTopTablesContent";
};
export type hundredTopTablesContent$key = {
  readonly " $data"?: hundredTopTablesContent$data;
  readonly " $fragmentSpreads": FragmentRefs<"hundredTopTablesContent">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "hundredTopTablesContent",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "entityLink"
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "withActionBar",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withCoverImage",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withCoverImageSize540x360",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withCoverImageSize768x768",
          "value": true
        },
        {
          "kind": "Literal",
          "name": "withHeadline",
          "value": true
        }
      ],
      "kind": "FragmentSpread",
      "name": "hooksContentItemProvidersContent"
    }
  ],
  "type": "Content",
  "abstractKey": "__isContent"
};

(node as any).hash = "3cd451d35d84b223b5f2043ff9a3307b";

export default node;
